.custom-editor-wrapper {
    /* width: 700px; */
    max-width: 42em;
    margin: 25px auto;
}

.lowerEditorService, .upperEditorService {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .upperEditorService p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    color: rgb(134, 134, 134);
    font-size: 13px;
    margin: 0px 2px;
  }
  

.lowerEditorService p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    color: rgb(182, 185, 185);
    font-size: 12px;
    margin: 2px 2px;
  }

.custom-editor {
    margin: 8px 0;
    border: 2px solid #eee;
    padding: 0 16px;
    border-radius: 5px;
}

.custom-editor-heading {
    color: rgb(78, 78, 78);
    font-weight: 700;
    font-size: 18px;
}

.custom-editor-toolbar{
    background-color:rgb(249, 249, 249);
    padding: 12px !important;
    display: flex;
    justify-content: space-between;
}

.custom-editor-toolbar .left-toolbar {
    border-right: 2px solid rgb(199, 199, 199);
}

.custom-editor-toolbar .left-toolbar svg{
    padding: 0 6px;
    fill: rgb(73, 73, 73);
    position: relative;
    top: 2px;
}

.custom-editor-toolbar .right-toolbar svg{
    padding: 0 3px;
    fill: rgb(73, 73, 73);
}

.icon-text-label {
    color: rgb(73, 73, 73);
    font-weight: 500;
    position: relative;
    top: -6px;
    margin-right: 10px;
}