body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropDown .MuiFormControl-root {
  margin: 0px 0;
  min-width: 100% !important;
  margin-left: 0;
}

.dropDown label{
  color: #1A1A1A !important;
  font-size: 16px;
}

.donation-card {
  width: 100%;
  padding-bottom: 20px;
  border-radius: 4px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--text-color) !important;

}

.MuiGrid-container form .MuiFormControl-root{
  margin-top: 0px;
}

.MuiOutlinedInput-input {
  padding: 0 15px !important;
  height: 46px !important;
}

.MuiInputLabel-formControl {
  top: -6px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -1px) scale(0.75) !important;
}

 .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -1px) scale(0.75) !important;
  color: var(--text-color) !important;
}

.Mui-focused{
 border-color: aqua !important;
 outline: aqua !important;
}

.Mui-focused span{color:  var(--text-color) !important;}

form {
  position: relative;
}

form span {
  text-indent: 0px !important;
  padding-top: 2px;
  font-size: 12px;
  padding-left: 15px !important;
  position: absolute;
  padding: 0 !important;
  left: 17px;
  margin-top: 4px !important;
}

 form .MuiTextField-root span{
  padding-left: 0px !important;
  text-indent: 8px !important;
  position: relative;
  left: 0px;
  bottom: 0px;
}

.MuiFormControl-root{
  position: relative;
}

.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px) !important;
  margin: 0 !important;
  margin-left: -8px !important;
}
br{
  display: none !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 8px;
  padding-top: 0 !important;
  /* padding-bottom: 32px !important; */
  padding-bottom: 32px;
}

