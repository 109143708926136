@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropDown .MuiFormControl-root {
  margin: 0px 0;
  min-width: 100% !important;
  margin-left: 0;
}

.dropDown label{
  color: #1A1A1A !important;
  font-size: 16px;
}

.donation-card {
  width: 100%;
  padding-bottom: 20px;
  border-radius: 4px;
}

.MuiInput-underline:after {
  border-bottom: 2px solid var(--text-color) !important;

}

.MuiGrid-container form .MuiFormControl-root{
  margin-top: 0px;
}

.MuiOutlinedInput-input {
  padding: 0 15px !important;
  height: 46px !important;
}

.MuiInputLabel-formControl {
  top: -6px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -1px) scale(0.75) !important;
}

 .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -1px) scale(0.75) !important;
  color: var(--text-color) !important;
}

.Mui-focused{
 border-color: aqua !important;
 outline: aqua !important;
}

.Mui-focused span{color:  var(--text-color) !important;}

form {
  position: relative;
}

form span {
  text-indent: 0px !important;
  padding-top: 2px;
  font-size: 12px;
  padding-left: 15px !important;
  position: absolute;
  padding: 0 !important;
  left: 17px;
  margin-top: 4px !important;
}

 form .MuiTextField-root span{
  padding-left: 0px !important;
  text-indent: 8px !important;
  position: relative;
  left: 0px;
  bottom: 0px;
}

.MuiFormControl-root{
  position: relative;
}

.MuiGrid-spacing-xs-2 {
  width: calc(100% + 16px) !important;
  margin: 0 !important;
  margin-left: -8px !important;
}
br{
  display: none !important;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
  padding: 8px;
  padding-top: 0 !important;
  /* padding-bottom: 32px !important; */
  padding-bottom: 32px;
}



.MuiAlert-filledSuccess {
  color: #fff;
  font-weight: 500;
  background-color: #05CE78 !important;
  border-radius: 0 !important;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.header_top_container{
  border-bottom: #DEDEDE 1px solid;
  padding: 15px;
  
}

.tpcontainer{
  max-width: 1140px !important;
  margin: 0 auto !important;;
}

.tp_hdr_right{
  float: right;
  font-size: 14px;
  text-transform: uppercase;
  color: #4C5E67;
  font-family: 'Roboto-Regular';
}

.tp_hdr_link{
  letter-spacing: 0.56px;
  color: #05CE78;
  text-transform: uppercase;
  font-size: 14x;
  font-family: 'Roboto-Bold';
  text-decoration: none;
}

header .MuiIconButton-label .MuiSvgIcon-root{
  color: #fff !important;
}

.logo{
  width: 173px;
  margin-top: 3px;
}



@media (max-width: 768px){

  .tp_hdr_right{
    float: none;
    text-align: center;
  }
  

.logo_link{
  display: block;
    text-align: center;
    width: 100%;
}

  .footer_wrapper .MuiGrid-item{
    width: 100%;
    text-align: center;
  }

  .footer_wrapper .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    display: inline-block!important;
    text-align: center;
    vertical-align: top;
    width: 100%;
}

}

.banner_container{
  display: flex;
  align-items: center;
}

.banner_left_col{
  width: 100%;
  text-align: center;
}

.banner_donation_form{
  min-width: 400px;
}

.banner_donation_form .MuiGrid-container {
  padding: 0 0 !important;
}

@media(max-width: 1270px){
  .banner_left_col{
    width: 100%;
  }
}

@media(max-width: 990px){

  .banner_container{
    display: block;
    text-align: center;
  }
  
  .banner_left_col{
    width: 100%;
    text-align: center;
  }
  .banner_donation_form{
    min-width: auto;
    width: 100%;
  }

  .banner_left_col h2{
    text-align:center !important;
    max-width: none;
    max-width: initial;
  }

  .banner_left_col p{
    text-align:center !important;
    max-width: none;
    max-width: initial;
  }

  .banner_left_col hr{
    margin: 30px auto;
    text-align: center;
  }

  .banner_donation_form .MuiGrid-item {
    margin: 0 !important;
    box-sizing: border-box;
}


  .banner_donation_form .MuiGrid-root{
    width: 100%;
  }

}










.ftr_btm_grey {
  background: #E4E8EC;
  padding: 15px;
  border-top: #ccc 1px solid;
}

.term_links {
  text-align: left;
}

.tx_box a {
  text-align: left;
  font-size: 12px;
  color: #828282;
  text-transform: uppercase;
  text-decoration: none;
}

.tx_box span {
  margin: 0 10px;
  display: inline-block;
}

.tx_box svg {
  margin: 0 5px;
}

.tx_box i {
  margin: 0 5px;
}

.ftr_btm {
  background: #fff;
  padding-top: 28px;
  padding-bottom: 28px;
  border-bottom: #7266FB 5px solid;
}

.powered_by {
  display: flex;
  align-items: center;
}

.right_aligh {
  justify-content: flex-end;
}

.tx_box {
  font-size: 12px;
  color: #828282;
  text-transform: uppercase;
}

.footer_container{
  max-width: 1140px;
  margin: 0 auto;
}

.rows{
  display: flex;
}

.rows .clom-6{
  width: 50%;
  padding: 0 15px;
}

.rows .clom-4{
  width: 33%;
  padding: 0 15px;
}

.text-right{
  text-align: right;
}

.dc_logo_ftr {
  width: 146px;
  margin-left: 5px;
}

.ver_aling {
  display: flex;
  align-items: center;
}

@media(max-width: 830px){

  .rows {
    display: block;
}

.rows .clom-6 {
  width: auto;
  padding: 0 15px;
}

.tx_box {
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
}

.rows .clom-4 {
  width: auto;
  padding: 0 0px;
  text-align: center;
}

.powered_by {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.ver_aling {
  display: block;
  align-items: center;
}

}



.success {
  -webkit-animation: seconds 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 5s;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 5s;
  position: relative;
    
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;     
  }
}
.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiTextField-root .MuiInputBase-input::-webkit-input-placeholder{
  position: relative;
  top: -5px;
}

.MuiTextField-root .MuiInputBase-input:-ms-input-placeholder{
  position: relative;
  top: -5px;
}

.MuiTextField-root .MuiInputBase-input::-ms-input-placeholder{
  position: relative;
  top: -5px;
}

.MuiTextField-root .MuiInputBase-input::placeholder{
  position: relative;
  top: -5px;
}

.MuiFormControlLabel-root span{
  font-family: 'Roboto-Regular' !important;
}

.MuiButton-root{
  box-shadow: none !important;
}

.donate_btn {
  color: #05CE78 !important;
  width: 140px;
  border: 1px solid #E4E8EC;
  height: 33px;
  background: #fff;
  margin-top: 25px;
  transition: 0.3s;
  display: inline-block;
  border-radius: 4px;
  padding-top: 13px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.MuiPaper-outlined:hover .donate_btn{
  background: #05CE78 !important;
  color: #fff !important;
}

.Inactive_image{
  position: relative;
  margin: 20px 30px;
}

.tab_container{
  max-width: 1140px;
  margin: 0  auto;
}

.tab_title {
  color: #4C5E67;
  margin: 0;
  font-size: 22px;
  text-align: left;
  font-family: Open Sans;
  font-weight: 600;
  line-height: 1.8;
}

.item_list {
  margin-right: 100px;
  padding: 0;
  float: left;
}
.item_list li{
  display:flex;
  align-items: flex-start;
  margin: 30px 0px;
}

.item_list li span {
  max-width: 400px;
  text-align: left;
  margin-left: 15px;
  font-family: Open Sans;
  font-size: 1rem;
  letter-spacing: 0.00938em;
}

@media(max-width: 768px){

  .item_list {
    margin-right: 0;
    padding: 0;
    float: none;
    margin-top: 0;
    margin-bottom: 0;
}
}


.MuiGrid-container .MuiInputBase-fullWidth .MuiInputBase-input{
  padding: 8px 0 7px !important;
  text-indent: 16px;
}

.MuiGrid-container .MuiInputLabel-formControl {
  top: -1px !important;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  left: 20px;
  color: #294C5C;
    opacity: 0.5;
}

.MuiGrid-container .MuiFormHelperText-contained {
  margin-left: 20px;
  margin-right: 15px;
  color: #BFCED7;
  font-size: 12px;
}
.MuiGrid-container .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-5px, -5px) scale(0.75) !important;
  color: var(--text-color) !important;
}

.MuiGrid-container .MuiFilledInput-root {
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color:transparent !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.MuiGrid-container .MuiFilledInput-underline:before {
  border:none !important;

}

.MuiGrid-container .MuiSelect-iconFilled {
  right: 7px;
  top: 18px;
}

.MuiGrid-container .MuiSelect-selectMenu {
  overflow: hidden;
  height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #BFCED7;
  border-radius: 4px;
  line-height: 49px;
}

.MuiGrid-container .textfieldmenu {}

.MuiGrid-container .textfieldmenu .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(-9px, -7px) scale(0.90);
  background: #fff;
  padding: 0 10px;
  color: #294c5c;
}

.MuiExpansionPanelDetails-root {
  padding: 0 !important;
}

.MuiExpansionPanelSummary-root{
  padding: 0 !important;
}

.MuiExpansionPanelSummary-content {
  margin: 24px 0 !important;
}

.contactForm .MuiGrid-spacing-xs-3 {
  width: calc(100% + 0px) !important;
  margin: 0 !important;
}
.custom-editor-wrapper {
    /* width: 700px; */
    max-width: 42em;
    margin: 25px auto;
}

.lowerEditorService, .upperEditorService {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .upperEditorService p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    color: rgb(134, 134, 134);
    font-size: 13px;
    margin: 0px 2px;
  }
  

.lowerEditorService p {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
    color: rgb(182, 185, 185);
    font-size: 12px;
    margin: 2px 2px;
  }

.custom-editor {
    margin: 8px 0;
    border: 2px solid #eee;
    padding: 0 16px;
    border-radius: 5px;
}

.custom-editor-heading {
    color: rgb(78, 78, 78);
    font-weight: 700;
    font-size: 18px;
}

.custom-editor-toolbar{
    background-color:rgb(249, 249, 249);
    padding: 12px !important;
    display: flex;
    justify-content: space-between;
}

.custom-editor-toolbar .left-toolbar {
    border-right: 2px solid rgb(199, 199, 199);
}

.custom-editor-toolbar .left-toolbar svg{
    padding: 0 6px;
    fill: rgb(73, 73, 73);
    position: relative;
    top: 2px;
}

.custom-editor-toolbar .right-toolbar svg{
    padding: 0 3px;
    fill: rgb(73, 73, 73);
}

.icon-text-label {
    color: rgb(73, 73, 73);
    font-weight: 500;
    position: relative;
    top: -6px;
    margin-right: 10px;
}
@font-face {
    font-family: 'Roboto-Regular';
    src: url(/static/media/Roboto-Regular.11eabca2.ttf);
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(/static/media/Roboto-Bold.e07df86c.ttf);
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(/static/media/Roboto-Medium.894a2ede.ttf);
}
@font-face {
    font-family: 'Roboto-Light';
    src: url(/static/media/Roboto-Light.46e48ce0.ttf);
}

@font-face {
    font-family: 'Helvetica';
    src: url(/static/media/Helvetica.1b580d98.ttf);
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url(/static/media/OpenSans-Regular.3ed9575d.ttf);
}

.App {
    width: 100%;
    height: 100vh;
    background-image: url(/static/media/Khushu-Pattern-Design_3.449c4906.png);
}

.MuiInputBase-inputAdornedStart{
    font-family: 'Roboto-Regular' !important;
}

.clearfix{
    clear: both;
}

.main_container{
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 25px;
}

.main_container_large{
    max-width: 1640px;
    margin: 0 auto;
    padding: 0 15px;
}

.MuiGrid-container{
    padding: 0;
}

@media(max-width:768px){
    .MuiTab-root {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
}

.page_body{
    background: #f7f9fa;
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
}

.page_inner_container{
    text-align: center;
    padding-top:80px;
}

.page_inner_container h1{
    font-size: 34px;
    color: #1B2121;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-top:0px;
    margin-bottom: 16px;
}

.page_inner_container .par_dl {
    font-size: 16px;
    color: #4C5E67;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    max-width: 418px;
    margin: 0 auto;
    line-height: 24px;
}

.page_inner_container .par_dl a{
    font-size: 16px;
    color: #2A8853;
}

.page_inner_container .go_btn {
    background-color: #2A8853;
    color: #fff;
    padding: 15px 30px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    margin: 0 auto;
    margin-top: 40px;
}

.error_page_header {
    background: #fff;
    box-shadow: 0px 1px 6px #0000000F;
    height: 80px;
}

.error_page_header .logo_link img{
    margin-top: 20px;
}

@media(max-width: 768px){
    .page_body {
        background: #f7f9fa;
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
    }

    .page_inner_container{
        padding: 80px 25px;
        padding-bottom: 0;
    }
}



