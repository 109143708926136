@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Roboto-Regular';
    src: url('./assets/Fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./assets/Fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('./assets/Fonts/Roboto-Medium.ttf');
}
@font-face {
    font-family: 'Roboto-Light';
    src: url('./assets/Fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Helvetica';
    src: url('./assets/Fonts/Helvetica.ttf');
}
@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./assets/Fonts/OpenSans-Regular.ttf');
}

.App {
    width: 100%;
    height: 100vh;
    background-image: url('./assets/img/Khushu-Pattern-Design_3.png');
}

.MuiInputBase-inputAdornedStart{
    font-family: 'Roboto-Regular' !important;
}

.clearfix{
    clear: both;
}

.main_container{
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 25px;
}

.main_container_large{
    max-width: 1640px;
    margin: 0 auto;
    padding: 0 15px;
}

.MuiGrid-container{
    padding: 0;
}

@media(max-width:768px){
    .MuiTab-root {
        min-width: 100%;
        width: 100%;
        max-width: 100%;
    }
}

.page_body{
    background: #f7f9fa;
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
}

.page_inner_container{
    text-align: center;
    padding-top:80px;
}

.page_inner_container h1{
    font-size: 34px;
    color: #1B2121;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin-top:0px;
    margin-bottom: 16px;
}

.page_inner_container .par_dl {
    font-size: 16px;
    color: #4C5E67;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    max-width: 418px;
    margin: 0 auto;
    line-height: 24px;
}

.page_inner_container .par_dl a{
    font-size: 16px;
    color: #2A8853;
}

.page_inner_container .go_btn {
    background-color: #2A8853;
    color: #fff;
    padding: 15px 30px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-decoration: none;
    border-radius: 4px;
    display: inline-block;
    margin: 0 auto;
    margin-top: 40px;
}

.error_page_header {
    background: #fff;
    box-shadow: 0px 1px 6px #0000000F;
    height: 80px;
}

.error_page_header .logo_link img{
    margin-top: 20px;
}

@media(max-width: 768px){
    .page_body {
        background: #f7f9fa;
        position: absolute;
        height: 100%;
        left: 0;
        width: 100%;
    }

    .page_inner_container{
        padding: 80px 25px;
        padding-bottom: 0;
    }
}


