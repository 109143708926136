.MuiGrid-container .MuiInputBase-fullWidth .MuiInputBase-input{
  padding: 8px 0 7px !important;
  text-indent: 16px;
}

.MuiGrid-container .MuiInputLabel-formControl {
  top: -1px !important;
  position: absolute;
  transform: translate(0, 24px) scale(1);
  left: 20px;
  color: #294C5C;
    opacity: 0.5;
}

.MuiGrid-container .MuiFormHelperText-contained {
  margin-left: 20px;
  margin-right: 15px;
  color: #BFCED7;
  font-size: 12px;
}
.MuiGrid-container .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-5px, -5px) scale(0.75) !important;
  color: var(--text-color) !important;
}

.MuiGrid-container .MuiFilledInput-root {
  position: relative;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  background-color:transparent !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.MuiGrid-container .MuiFilledInput-underline:before {
  border:none !important;

}

.MuiGrid-container .MuiSelect-iconFilled {
  right: 7px;
  top: 18px;
}

.MuiGrid-container .MuiSelect-selectMenu {
  overflow: hidden;
  height: 46px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #BFCED7;
  border-radius: 4px;
  line-height: 49px;
}

.MuiGrid-container .textfieldmenu {}

.MuiGrid-container .textfieldmenu .MuiInputLabel-filled.MuiInputLabel-shrink {
  transform: translate(-9px, -7px) scale(0.90);
  background: #fff;
  padding: 0 10px;
  color: #294c5c;
}

.MuiExpansionPanelDetails-root {
  padding: 0 !important;
}

.MuiExpansionPanelSummary-root{
  padding: 0 !important;
}

.MuiExpansionPanelSummary-content {
  margin: 24px 0 !important;
}

.contactForm .MuiGrid-spacing-xs-3 {
  width: calc(100% + 0px) !important;
  margin: 0 !important;
}